<template>
<div >
  <el-form ref="form" inline  :model="records" hide-required-asterisk style="background: #fff;height:100%">
    <el-row >  
      <el-col >  
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-document-copy"></i>任务类型</span>
            <el-button type="primary"  style="float:right; margin-left:20px">{{records.scheduler_type}}</el-button>
          </div>
          <div class="setSpan" >
            <p class="text item">发送状态:
              <el-button :type="records.sms_send_status === '发送完成' ? 'success' :(records.sms_send_status === '等待中' ? 'warning' : 'danger' )" disabled plain >{{records.sms_send_status}}</el-button>
            </p>
            <p class="text item">任务标题:<span>{{records.sms_theme}}</span></p>
            <p class="text item">过滤已购(天):<span>{{records.purchase_time_filter}}</span></p>
            <p class="text item">过滤近期发送(天):<span>{{records.filter_condition}}</span></p>
            <p class="text item">任务job ID:<span>{{records.scheduler_job_id}}</span></p>
            <p class="text item">创建任务时填写的数据:<span  v-if="records.scheduler_type =='定时任务'">{{records.original_create_time_data}}</span></p>
            <p class="text item">任务创建时间:<span>{{records.sms_send_time}}</span></p>
            <p class="text item" v-if="records.scheduler_status =='运行中'">下次执行时间:<span>{{records.next_run_time}}</span></p>
            <p class="text item" >该任务<i>上</i>次执行时发送短信(条):<span>{{records.last_task_message_record_number}}</span></p>
            <p class="text item" v-if="records.scheduler_status =='运行中'">该任务<i>本</i>次执行预计发送短信(条):<span>{{page_info.total}}</span></p>
          </div>
          <span style="float:right" class="state">任务状态:
            <span v-if="records.scheduler_status =='已暂停'" style="color:#E6A23C;">{{records.scheduler_status}}</span>
            <span v-if="records.scheduler_status =='已删除'" style="color:#F56C6C;">{{records.scheduler_status}}</span>
            <span v-if="records.scheduler_status =='运行中'" style="color:#409EFF;">{{records.scheduler_status}}</span>
          </span>
      </el-card>
    </el-col> 
    <el-col > 
      <el-card class="box-card"> 
        <div slot="header" class="clearfix">
          <span> <i class="el-icon-document"></i>{{records.template_title}}</span>
          <el-button  style="float:right; margin-left:20px"> <i class="el-icon-user-solid"></i>{{records.template_id}}</el-button>
        </div>
        <p class="roundrect-bot">{{records.template_content}}</p>
      </el-card>
      <el-card class="box-card" > 
        <span><i class="el-icon-collection-tag"></i> 根据查询条件生成的Sql语句:</span>
        <div class="SqlText">
          <p>{{records.sql_text}}</p>
        </div>
      </el-card>
  </el-col> 
 </el-row> 
   <el-table :data="sql_result"  border   id="table" :header-cell-style="headClass" >  
      <el-table-column  v-for="(item,key) in sql_result[0]" :key="key" align="center"> 
        <template slot="header">{{key|filterConditions}}</template>
        <template slot-scope="scope">{{sql_result[scope.$index][key]}}</template>
      </el-table-column>
  </el-table>
  <div>
     <pagination
      v-show="page_info.total>0"
        :total="page_info.total"
        :page.sync="page_info.current_page"
        :limit.sync="page_info.page_size"
        @pagination="getSmsRecord"
      />
  </div>
 </el-form>
</div>
</template>
<script>

export default {
  props: ['row'],
  data() {
    const { row } =  this;
    return {
      sql_result:[],
      scheduler_job_id:this.$route.query.scheduler_job_id,
       page_info: {
        total: 0, 
        page_size: 10, 
        current_page: 1,
      },
      records:{},
    }
  },

  created() {
    if(this.scheduler_job_id){
        this.getSmsRecord()
    }else{
        return this.$message.error("请返回创建记录页面选择任务查看")
    }
  },

  methods: {

     headClass() {
      return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },
    
    async getSmsRecord() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0.5)',
        target: document.querySelector('#table')
    });
    let params = {
      current_page: this.page_info.current_page,
      page_size: this.page_info.page_size,
      scheduler_job_id:this.scheduler_job_id
      };
      const res = await this.$http.get("/periodic_task/details/get", params);
        this.records=res.data.data.records;
        this.sql_result=res.data.data.sql_result;
        this.page_info.total = res.data.data.total;
        loading.close()
    },
  },
};
</script>
<style lang="scss" scoped>
.el-row{
  display: flex;
}
.el-col{
  justify-content: space-between;
  align-items:center
}
.SqlText{
  border: 1px solid #EBEEF5;
  margin: 10px;
  padding: 0 10px 0 10px;
  border-radius: 10px;
  span{
    font-size: 14px;
  }
  p{
    color: #409EFF;
    font-size: 14px;
    word-wrap: break-word;
    word-break: normal;
  }
}
.el-table{
    margin: 10px;
}
.setSpan span{
    color:#409EFF;
    padding-left: 20px;
}
.setSpan p{
    border-bottom: 1px solid #EBEEF5;
}
.el-form-item{
    margin: 20px;
}
.text {
  font-size: 16px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.box-card {
  margin: 20px;
}
.state{
  padding-bottom: 10px;
}
.roundrect-bot {
  background: #cae1ff;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  position: relative;
  word-break:break-all;
  padding: 14px;
  margin: 10px 10px;
  line-height: 20px;
  color: #000;
}
.el-table{
  color: #000;
}

</style>
